import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import { invertHeader, resetHeader } from "../../actions"
import { connect } from "react-redux"

import WorkList from "./WorkList"
import ContentFilters from "../../components/ContentFilters"

class WorkIndex extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.invertHeader()
  }

  componentWillUnmount() {
    this.props.resetHeader()
  }

  render() {
    const ContentFiltersComponent = () => (
      <ContentFilters
        indexType={`work`}
        workCategories={this.props.data.allWorkCategories}
        brands={this.props.data.allBrands}
        offices={this.props.data.allOffices}
        classics={true}
        workDesign={true}
      />
    )

    return (
      <Layout>
        <SEO title={`Work`} description={`Work`} />
        <div>
          <WorkList
            ContentFilters={ContentFiltersComponent}
            allWork={this.props.data.allWork}
          />
        </div>
      </Layout>
    )
  }
}


export default connect(
  null,
  {
    invertHeader,
    resetHeader,
  }
)(WorkIndex)

export const query = graphql`
  query WorkIndex {
    allBrands: allContentfulClients(
      sort: { fields: [brandName], order: ASC  }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          node_locale
          brandName
          slug 
          work {
            ...workFragmentBase
          }
        }
      }
    }

    allWorkCategories: allContentfulWorkCategories(
      sort: { fields: [name], order: ASC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          node_locale
          name
          slug
          work {
            ...workFragmentBase
          }
        }
      }
    }

    allOffices: allContentfulOffices(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "en-US" }, venture: { eq: false } }
    ) {
      edges {
        node {
          ...officeFragment
        }
      }
    }

    allWork: allContentfulWork(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "en-US" }, workDesign: { ne: true } }
    ) {
      edges {
        node {
          ...workFragmentBase
        }
      }
    }
  }
`
